@font-face {
    font-family: 'SFProMedium';
    src: local('SFProMedium'), url(./assets/fonts/SFProMedium.ttf) format('opentype');
}
@font-face {
    font-family: 'SFProBold';
    src: local('SFProBold'), url(./assets/fonts/SFProBold.ttf) format('opentype');
}
@font-face {
    font-family: 'SFProSemibold';
    src: local('SFProSemibold'), url(./assets/fonts/SFProSemibold.ttf) format('opentype');
}
@font-face {
    font-family: 'SFProHeavy';
    src: local('SFProHeavy'), url(./assets/fonts/SFProHeavy.ttf) format('opentype');
}
@font-face {
    font-family: 'SFProText';
    src: local('SFProRegular'), url(./assets/fonts/SFProRegular.ttf) format('opentype');
}

html {
    scroll-behavior: smooth;
}

html body {
    margin: 0;
    font-family: SFProText;
    letter-spacing: 0.8px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
